import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import parseUrl from "parse-url";

import { withRouter, useParams } from "react-router-dom";
import { Layout, Switch, Row, Col } from "antd";
import HeaderMain from "../components/HeaderMain";
// import FooterMain from "../components/FooterMain";
// import Search from "../components/search";

import GoogleAdsenseResponsive from "../components/GoogleAdsenseResponsive";

import ProductWebSearch from "../components/ProductWebSearch";
import TemplateProductSmallView from "../components/product/TemplateProductSmallView";

import WebsitesLoader from "../components/WebsitesLoader";
import PaginationPanel from "../components/PaginationPanel";

import * as actions from "../redux/actions";

import FooterMain from "../components/FooterMain";
import ProductsSort from "../components/ProductsSort";

const { Content } = Layout;

function PageProducts(props) {
  const { products, history, countTotalProducts, totalPages } = props;
  const { location } = history;

  let params = useParams();
  let { categoryId, website } = params;

  let defaultPageNumber = 1;
  let urlPageNumber = new URLSearchParams(props.location.search).get("page");
  if (urlPageNumber && urlPageNumber > 1) {
    defaultPageNumber = urlPageNumber;
  }

  let pageHeading = "Products";

  // identify catalog type from URL
  let catalogType = "catalog";
  let preCheckCatalogType = props?.location?.pathname;
  if (preCheckCatalogType !== "") {
    if (preCheckCatalogType.indexOf("/price-drop-products") !== -1) {
      catalogType = "priceDropProducts";
      pageHeading = "Price Drop Products";
    } else if (preCheckCatalogType.indexOf("/popular-products") !== -1) {
      catalogType = "popularProducts";
      pageHeading = "Popular Products";
    } else if (preCheckCatalogType.indexOf("/recent-products") !== -1) {
      catalogType = "recentProducts";
      pageHeading = "Recent Products";
    }
  }

  const [currentPage, setCurrentPage] = useState(defaultPageNumber);
  const [sortOpted, setSortOpted] = useState("");

  const _onPageChange = (newPage) => {
    // const { pathname } = location;
    // let newUrl = pathname + "?page=" + newPage;
    //
    // props.history.push(newUrl);
    // let queryObject = parseUrl(window.location.href).query;
    // setSortOpted(queryObject?.sort || "");

    const { pathname } = location;
    let searchParams = new URLSearchParams(props.location.search);
    searchParams.set("page", newPage);
    setCurrentPage(newPage);
    props.history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const onSortChange = (data) => {
    // setCurrentPage(1); // reset page to 1
    const { pathname } = location;
    let searchParams = new URLSearchParams(props.location.search);
    searchParams.set("sort", data);
    searchParams.delete("page");
    props.history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  // on url change call fresh API
  useEffect(() => {
    let queryObject = parseUrl(window.location.href).query;

    setSortOpted(queryObject?.sort || "");

    let queryParams = {
      ...queryObject,
      catalogType: catalogType,
    };
    if (categoryId) {
      queryParams["categoryId"] = categoryId;
    }
    if (website) {
      queryParams["website"] = website;
      queryParams["catalogType"] = "";
    }

    props.getCatalogProductsRequest(queryParams);
  }, [location]);

  useEffect(() => {
    setCurrentPage(defaultPageNumber);
  }, [defaultPageNumber]);

  return (
    <div id="search-page">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="price dropped products, price drop items, price drop checker, price drops, price slashed,  price drop website, price drop alert, cheap price"
        />
        <meta
          name="description"
          content="Cheapshops.in - Latest products from amazon, nykaa, flipkart, myntra, price history"
        />
        <meta
          name="title"
          content="Cheapshops.in - Latest products from amazon, nykaa, flipkart, myntra, price history"
        />
        <title>
          Cheapshops.in - Latest products from amazon, nykaa, flipkart, myntra,
          price history
        </title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HeaderMain />
      {/* <Content className="page-content"><Search /></Content> */}
      {/* <FooterMain /> */}
      <div className="page-content">
        {products && products.length > 0 ? (
          <Row gutter={16}>
            <GoogleAdsenseResponsive />
          </Row>
        ) : null}

        <Row>
          <Col md={12} xl={12} sm={12} xs={12}>
            <h1 style={{ marginLeft: 10, fontSize: 20, color: "#1f1c4f" }}>
              {pageHeading}
            </h1>
          </Col>
          <Col
            md={12}
            xl={12}
            sm={12}
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ProductsSort onSortChange={onSortChange} defaultSort={sortOpted} />
          </Col>
          {/* <Col
            md={12}
            xl={12}
            sm={12}
            xs={24}
            style={{ textAlign: "right", paddingRight: "10px", color: "white" }}
          >
            <Switch onChange={props.toggleShowAllSearchProducts} /> Show all
          </Col> */}
        </Row>
        {totalPages && totalPages > 1 ? (
          <>
            <Row gutter={16}>
              <Col md={24} xl={24} sm={24} xs={24} className="textCenter">
                <PaginationPanel
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={_onPageChange}
                />
              </Col>
            </Row>
            <br />
          </>
        ) : null}
        {/* <WebsitesLoader websitesList={websites} /> */}
        {/* {recentProducts.map((product, index) => {
          return <ProductWebSearch data={product} key={index} />;
        })} */}
        <Row gutter={16}>
          {products.map((product, index) => {
            return <TemplateProductSmallView data={product} key={index} />;
          })}
        </Row>
        {totalPages && totalPages > 1 ? (
          <Row gutter={16}>
            <Col md={24} xl={24} sm={24} xs={24} className="textCenter">
              <PaginationPanel
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={_onPageChange}
              />
            </Col>
          </Row>
        ) : null}

        {products && products.length > 0 ? (
          <Row gutter={16}>
            <br />
            <br />
            <GoogleAdsenseResponsive />
          </Row>
        ) : null}
      </div>
      <FooterMain />
    </div>
  );
}

const mapStateToProps = (state) => ({
  products: state.products.products.data.products || [],
  countTotalProducts: state.products.products.data.countTotalProducts || 0,
  totalPages: state.products.products.data.totalPages || 0,
});

const mapDispatchToProps = (dispatch) => ({
  getCatalogProductsRequest: (data) =>
    dispatch(actions.getCatalogProducts.request(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageProducts)
);
