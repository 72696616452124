import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import Menu from "../components/Menu";

function PageAllCategoires(props) {
  let title = "Find online product price - Cheapshops.in";
  if (window.DOMAIN_NAME === "price2click") {
    title = "Find online product price - Price2click.com";
  }
  return (
    <div id="page-all-categories">
      <Helmet>
        <meta charSet="utf-8" />
        {/* <meta name="description" content={metaDescription} /> */}
        {/* <meta name="title" content={metaTitle} /> */}
        <title>{title}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HeaderMain />
      <div className="page-content">
        <div className="wrapper">
          <Menu />
        </div>
      </div>
      <FooterMain />
    </div>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageAllCategoires)
);
