import React from "react";
import { Layout, Row, Col } from "antd";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

const { Content } = Layout;

function PageAllStores(props) {
  const { websitesToProcess } = props;

  const openWebsite = (w) => {
    if (w.landingPage) {
      window.open(w.landingPage, "_blank");
    }
  };

  let title = "Find online product price - Cheapshops.in";
  if (window.DOMAIN_NAME === "price2click") {
    title = "Find online product price - Price2click.com";
  }

  return (
    <div id="page-all-stores">
      <Helmet>
        <meta charSet="utf-8" />
        {/* <meta name="description" content={metaDescription} /> */}
        {/* <meta name="title" content={metaTitle} /> */}
        <title>{title}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HeaderMain />
      <div className="page-content">
        <div className="websites-list">
          <div style={{ color: "#1f1c4f", textAlign: "center" }}>
            All stores:
          </div>
          <Row>
            {websitesToProcess
              .filter((w) => !w.isDisabled)
              .map((wp, index) => {
                const { selectedForProcess, logo, website } = wp;
                let blockClass = "website-block";
                if (!selectedForProcess) {
                  //   blockClass += " block-not-selected";
                }
                return (
                  <Col
                    key={index}
                    className={blockClass}
                    xl={3}
                    md={3}
                    xs={4}
                    onClick={() => openWebsite(wp)}
                  >
                    <a href={wp.landingPage} target="_BLANK">
                      <img src={logo} className="website-logo" alt={website} />
                    </a>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <FooterMain />
    </div>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageAllStores)
);
