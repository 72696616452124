import React from "react";
import { connect } from "react-redux";
import { Dropdown, Space } from "antd";
import ReactCountryFlag from "react-country-flag";

import {
  setCountryCode,
  getCountryCode,
  saveUserSearchCategoty,
  saveUserWebsitesToProcess,
} from "../utils/Generic";

let redirectUrl = "https://cheapshops.in";
if (window.location.host.indexOf("price2click.com") !== -1) {
  redirectUrl = "https://price2click.com";
}

const CountrySelection = (props) => {
  const { countriesList } = props;
  // console.log(countriesList);
  let selectedCountryCode = getCountryCode();
  // console.log(selectedCountryCode);
  const handleChange = (e) => {
    saveUserSearchCategoty("");
    saveUserWebsitesToProcess("");
    setCountryCode(e.key);
    // console.log(redirectUrl);
    window.location.replace(redirectUrl);
  };
  let items = [];
  countriesList.forEach((country) => {
    const { isActive, flagCode, name } = country;
    if (isActive) {
      items.push({
        key: country?.code,
        label: name,
        icon: (
          <ReactCountryFlag
            style={{ fontSize: 30 }}
            countryCode={flagCode}
            svg
          />
        ),
      });
    }
  });

  let selectedFlag = items.find((f) => f.key === selectedCountryCode);

  return (
    <>
      <Dropdown
        overlayClassName="dropdown-countries"
        trigger="click"
        menu={{
          items,
          onClick: handleChange,
          defaultSelectedKeys: selectedFlag?.key || "",
        }}
      >
        <a onClick={(e) => console.log(e.target.value)}>
          <Space>{selectedFlag?.icon}</Space>
          {/* <span style={{ fontWeight: "bold" }}>{selectedFlag?.label}</span> */}
        </a>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state?.generic?.menu?.data?.countriesList || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelection);
