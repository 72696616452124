// below is old method which was used on the basis of env file
// export const base_url = process.env.REACT_APP_BASE_URL;

const getApiUrl = () => {
  let currentUrl = window.location.host;
  //   return "https://price2click.com/api"; // use this for local use
  if (currentUrl.indexOf("price2click.com") !== -1) {
    return "https://price2click.com/api";
  } else if (currentUrl.indexOf("localhost:3000") !== -1) {
    return "http://localhost:4006";
  } else {
    return "https://cheapshops.in/api";
  }
};

export const base_url = getApiUrl();

export const jwtsecret = "CH";
