import React from "react";
import { connect } from "react-redux";
import { Col, Progress } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { MagnifyingGlass } from "react-loader-spinner";
import * as actions from "../redux/actions";

function WebsitesLoaderNew(props) {
  const { websitesToProcess } = props;
  let websitesToList = websitesToProcess.filter(
    (w) => w.selectedForProcess === true
  );

  const conicColors = {
    "0%": "#ffccc7",
    "50%": "#ffe58f",
    "100%": "#87d068",
  };

  //   const getLoaderBlock = () => {
  //      const { logo, isProcessed } = website;
  //     return <Progress type="circle" percent={100} format={() => "Done"} />;
  //   };

  let count_total_websites = websitesToList.length;
  let count_processed = websitesToList.filter(
    (obj) => obj.isProcessed === true
  ).length;

  let percentage = (count_processed / count_total_websites) * 100;

  //   console.log("count_total_websites -- " + count_total_websites);
  //   console.log("count_processed -- " + count_processed);
  //   console.log("percentage -- " + percentage);

  let websiteToShow = "";
  let logoToshow = "";

  if (websitesToList.length > 0) {
    let pendingIsProcessed =
      websitesToList && websitesToList.find((w) => w.isProcessed !== true);
    websiteToShow = pendingIsProcessed?.website;
    logoToshow = pendingIsProcessed?.logo;
  }

  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <Progress
        type="dashboard"
        percent={percentage}
        size="small"
        // steps={count_total_websites}
        strokeWidth={5}
        steps={{
          count: count_total_websites,
          gap: 8,
        }}
        strokeColor={conicColors}
        format={() => (
          <div>
            {count_processed === count_total_websites ? (
              <CheckOutlined />
            ) : (
              <>
                <MagnifyingGlass
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="magnifying-glass-loading"
                  wrapperStyle={{}}
                  wrapperClass="magnifying-glass-wrapper"
                  glassColor="#c0efff"
                  color="#e15b64"
                />
                <br />
                <img
                  src={logoToshow}
                  style={{ width: "60%" }}
                  alt={websiteToShow}
                />
                <br />
                <span style={{ fontSize: 12 }}>
                  {count_processed}/{count_total_websites}
                </span>
              </>
            )}
          </div>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
});

const mapDispatchToProps = (dispatch) => ({
  productWebSearchRequest: (data) =>
    dispatch(actions.productWebSearchRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsitesLoaderNew);
